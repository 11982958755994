import {
  Button,
  Grid,
  makeStyles,
  SvgIcon
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { Edit as EditIcon, PlusCircle as PlusCircleIcon } from 'react-feather';

import BreadCrumbs from './BreadCrumbs';

const useStyles = makeStyles((theme) => ({
  root: {},
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  }
}));

const Header = ({
                  editFn,
                  editBtnText,
                  createFn,
                  createBtnText,
                  breadCrumbTitle,
                  breadCrumbItems,
                  customComponents,
                  className,
                  ...rest
                }) => {
    const classes = useStyles();
    return (
      <Grid className={clsx(classes.root, className)}
            container
            justify='space-between'
            spacing={3}
            {...rest}
      >
        <BreadCrumbs title={breadCrumbTitle}
                     items={breadCrumbItems} />
        {Boolean(createFn) && (
          <Grid item>
            <Button
              color='secondary'
              variant='contained'
              onClick={() => createFn()}
              startIcon={
                <SvgIcon fontSize='small'>
                  <PlusCircleIcon />
                </SvgIcon>
              }
            >
              {createBtnText || 'create'}
            </Button>
          </Grid>
        )}
        {Boolean(editFn) && (
          <Grid item>
            <Button
              color='secondary'
              variant='contained'
              onClick={() => editFn()}
              startIcon={
                <SvgIcon fontSize='small'>
                  <EditIcon />
                </SvgIcon>
              }
            >
              {editBtnText || 'edit'}
            </Button>
          </Grid>
        )}
        {Boolean(customComponents) && <Grid item>
            {customComponents}
          </Grid>
        }
      </Grid>
    );
  }
;

Header.propTypes = {
  breadCrumbTitle: PropTypes.string.isRequired,
  breadCrumbItems: PropTypes.array.isRequired,
  editFn: PropTypes.func,
  editBtnText: PropTypes.string,
  createFn: PropTypes.func,
  createBtnText: PropTypes.string,
  customComponents: PropTypes.node,
  className: PropTypes.string,
};

export default Header;
