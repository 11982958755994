import {
  IconButton,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Tooltip
} from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';
import React, {
  useRef,
  useState
} from 'react';
import {
  ArrowRight as ArrowRightIcon,
  Edit as EditIcon,
  Trash as TrashIcon
} from 'react-feather';
const useStyles = makeStyles(() => ({
  menu: {
    width: 256,
    maxWidth: '100%'
  }
}));

const EditMoreButton = ({
                          handleUpdateFn,
                          handleDeleteFn,
                          handleViewFn,
                          ...rest
                        }) => {
  const classes = useStyles();
  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  return (
    <>
      <Tooltip title='More options'>
        <IconButton
          onClick={handleMenuOpen}
          ref={moreRef}
          {...rest}
        >
          <MoreIcon fontSize='small' />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={moreRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={handleMenuClose}
        open={openMenu}
        PaperProps={{ className: classes.menu }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        {
          handleUpdateFn &&
          (<MenuItem onClick={handleUpdateFn}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText primary='Edit' />
          </MenuItem>)
        }
        {
          handleDeleteFn && (
            <MenuItem onClick={handleDeleteFn}>
              <ListItemIcon>
                <TrashIcon />
              </ListItemIcon>
              <ListItemText primary='Delete' />
            </MenuItem>
          )
        }
        {
          handleViewFn && (
            <MenuItem onClick={handleViewFn}>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary='View' />
            </MenuItem>
          )
        }
      </Menu>
    </>
  );
};

EditMoreButton.propTypes = {
  handleViewFn: PropTypes.func,
  handleDeleteFn: PropTypes.func,
  handleUpdateFn: PropTypes.func
};

export default EditMoreButton;
