import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  makeStyles
} from '@material-ui/core';
import React, {
  createContext,
  useState
} from 'react';


const DEFAULT_OPTIONS = {
  className: '',
  title: '',
  message: '',
  element: null,
  okColor: 'primary',
  cancelColor: 'default',
  hideOk: false,
  hideCancel: true,
  okText: 'Ok',
  cancelText: 'Cancel',
  onOk: () => null,
  onCancel: () => null,
  fullWidth: true,
  fullScreen: false,
  maxWidth: false
};

const useStyles = makeStyles((_theme) =>
  createStyles({
    root: {
      height: '100%'
    },
    content: {
      minHeight: 150
    },
    title: {
      fontSize: '18px'
    }
  })
);

export const DialogContext = createContext({});


export const DialogProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState(DEFAULT_OPTIONS);
  const {
    className,
    title,
    message,
    element,
    okColor,
    cancelColor,
    hideOk,
    hideCancel,
    okText,
    cancelText,
    onOk,
    onCancel,
    fullScreen,
    fullWidth,
    maxWidth
  } = options;
  const html = {
    __html: message || ''
  };
  const classes = useStyles(options);
  const showActions = !(hideOk && hideCancel);

  const confirmHandler = (options) => {
    setOptions(options);
    setOpen(true);
  };

  const alertHandler = (options) => {
    setOptions({
      ...options,
      okColor: options.color,
      hideOk: true,
      hideCancel: true
    });
    setOpen(true);
  };

  const messageHandler = (options) => {
    setOptions({
      ...options,
      okColor: options.color,
      hideOk: false,
      hideCancel: true
    });
    setOpen(true);
  };

  const okHandler = () => {
    setOpen(false);
    if (onOk) {
      onOk();
    }
  };

  const closeHandler = () => {
    setOpen(false);
    if (onCancel) {
      onCancel();
    }
  };
  return (
    <Box className={classes.root}>
      <DialogContext.Provider value={{
        openConfirm: confirmHandler,
        openAlert: alertHandler,
        openMessage: messageHandler,
        close: closeHandler
      }}>
        {children}
        <Dialog
          fullWidth={fullWidth || true}
          fullScreen={fullScreen || false}
          maxWidth={maxWidth || 'xs'}
          open={open}
          aria-labelledby='dialog-title'
          aria-describedby='dialog-description'
        >
          {
            title &&
            <>
              <DialogTitle
                id='dialog-title'
                className={classes.title}
              >
                {title}
              </DialogTitle>
              <Divider />
            </>
          }
          {
            message &&
            <DialogContent className={`${classes.content} ${className}`}>
              <DialogContentText id='confirm-dialog-description'>
                <span dangerouslySetInnerHTML={html} />
              </DialogContentText>
            </DialogContent>
          }
          {
            element &&
            <Box className={`${classes.content} ${className}`}>
              {element}
            </Box>
          }
          <Divider />
          {
            showActions &&
            <DialogActions>
              {
                !hideOk &&
                <Button
                  variant='contained'
                  color={okColor || 'primary'}
                  onClick={okHandler}
                >
                  {okText || 'Ok'}
                </Button>
              }
              {
                !hideCancel &&
                <Button
                  color={cancelColor || 'default'}
                  onClick={closeHandler}
                >
                  {cancelText || 'Cancel'}
                </Button>
              }
            </DialogActions>
          }
        </Dialog>
      </DialogContext.Provider>
    </Box>
  );
};
