export const APP_VERSION = '3.1.0';

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK'
};

export const ROLES = {
  ADMIN: 'admin',
  USER: 'user'
};

export const STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  ARCHIVED: 'archived',
  PROCESSING: 'processing',
  QUEUED: 'queued',
  FAILED: 'failed',
  PROCESSED: 'processed'
};

export const STATUS_COLOR = {
  active: '#87cefa',
  archived: '#d2b48c',
  available: '#03c03c',
  inactive: '#a9a9a9',
  processing: '#4169e1'
};

