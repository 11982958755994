import Avatar from '@material-ui/core/Avatar';
import isRetina from 'is-retina';
import md5 from 'md5';
import PropTypes from 'prop-types';
import querystring from 'query-string';
import React from 'react';

class Gravatar extends React.Component {
  static displayName = 'Gravatar';
  static propTypes = {
    email: PropTypes.string,
    md5: PropTypes.string,
    size: PropTypes.number,
    rating: PropTypes.string,
    default: PropTypes.string,
    className: PropTypes.string,
    protocol: PropTypes.string,
    domain: PropTypes.string
  };
  static defaultProps = {
    size: 50,
    rating: 'g',
    default: 'retro',
    protocol: '//',
    domain: 'www.gravatar.com'
  };

  render() {
    const base = `${this.props.protocol}${this.props.domain}/avatar/`;

    const query = querystring.stringify({
      s: this.props.size,
      r: this.props.rating,
      d: this.props.default
    });

    const retinaQuery = querystring.stringify({
      s: this.props.size * 2,
      r: this.props.rating,
      d: this.props.default
    });

    // Gravatar service currently trims and lowercases all registered emails
    const formattedEmail = ('' + this.props.email).trim().toLowerCase();

    let hash;
    if (this.props.md5) {
      hash = this.props.md5;
    } else if (typeof this.props.email === 'string') {
      hash = md5(formattedEmail, { encoding: 'binary' });
    } else {
      // eslint-disable-next-line no-console
      console.warn(
        'Gravatar image can not be fetched. Either the "email" or "md5" prop must be specified.'
      );
      return (<script />);
    }

    const src = `${base}${hash}?${query}`;
    const retinaSrc = `${base}${hash}?${retinaQuery}`;

    let modernBrowser = true;  // server-side, we render for modern browsers

    if (typeof window !== 'undefined') {
      // this is not NodeJS
      modernBrowser = 'srcset' in document.createElement('img');
    }

    let className = 'react-gravatar';
    if (this.props.className) {
      className = `${className} ${this.props.className}`;
    }

    // Clone this.props and then delete Component specific props so we can
    // spread the rest into the img.
    let { ...rest } = this.props;
    delete rest.md5;
    delete rest.email;
    delete rest.protocol;
    delete rest.rating;
    delete rest.size;
    delete rest.style;
    delete rest.className;
    delete rest.default;
    if (!modernBrowser && isRetina()) {
      return (
        <Avatar
          ref={React.createRef()}
          alt={formattedEmail}
          className={className}
          src={retinaSrc}
          imgProps={{ width: this.props.size, height: this.props.size }}
          {...rest}
        />
      );
    }
    return (
      <Avatar
        ref={React.createRef()}
        alt={formattedEmail}
        className={className}
        src={src}
        srcSet={`${retinaSrc} 2x`}
        imgProps={{ width: this.props.size, height: this.props.size }}
        {...rest}
      />
    );
  }
}


export default Gravatar;
