export const parseInitialData = (rows) => rows.map(o => ({ ...o }));

export const openExternalLink = (link) => {
  window.open(link, '_blank', 'noopener,noreferrer');
};

export const getDefaultSort = (idx, colId, direction) => {
  return colId === idx && direction ? direction : null;
};

export const insertAt = (arr, index, ...elements) => {
  arr.splice(index, 0, ...elements);
}

export const getUserTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};
