import {
  CircularProgress,
  TextField
} from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Autocomplete from '@material-ui/lab/Autocomplete';
import find from 'lodash/find';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useQuery } from 'react-query';

const AutocompleteField = ({
                             label,
                             control,
                             queryKey,
                             disabled,
                             autocompleteFn,
                             optionsBuilderFn,
                             optionSelectedFn,
                             optionLabelFn,
                             renderOptionFn,
                             onChangeFn,
                             ...rest
                           }) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [filter, setFilter] = useState({});
  const [value, setValue] = useState(null);
  const { isLoading, isError, data } = useQuery(
    [queryKey, { filter }],
    () => autocompleteFn(filter),
    {
      cacheTime: 0
    }
  );

  const onChangeHandle = (input) => {
    let trimmed = (input && input.trim()) || input;

    if (trimmed.length > 2 && value !== trimmed) {
      const currentValue = value;
      if (
        currentValue &&
        currentValue.length > 2 &&
        (new RegExp(`^${currentValue}`)).test(trimmed)
      ) {
        return true;
      }
      // if we don't have a match in options do a new request
      if (!find(options, (o) => o.label.indexOf(trimmed) !== -1)) {
        setFilter({ name: { $regex: `^${trimmed}` } });
      }
    }
    if (!trimmed.length) {
      setFilter({});
    }
    setValue(trimmed);
  };

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
    if (!isLoading && data && data.results) {
      setOptions(optionsBuilderFn(data.results));
    }
  }, [data, isLoading, open, optionsBuilderFn]);

  return (
    <Controller
      render={(props) => (
        <Autocomplete
          {...props}
          open={open}
          disabled={disabled}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onChange={(e, data) => {
            onChangeFn && onChangeFn(data);
            // eslint-disable-next-line react/prop-types
            props.onChange(data);
          }}
          onInputChange={(e, newInputValue) => {
            onChangeHandle(newInputValue);
          }}
          getOptionSelected={optionSelectedFn}
          getOptionLabel={optionLabelFn}
          renderOption={renderOptionFn}
          options={options}
          autoComplete
          loading={isLoading}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant='outlined'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isError && <ErrorOutlineIcon color='error'
                                                  size={20} />}
                    {isLoading && <CircularProgress color='inherit'
                                                    size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      )}
      control={control}
      {...rest}
    />
  );
};

AutocompleteField.propTypes = {
  label: PropTypes.string.isRequired,
  control: PropTypes.any.isRequired,
  queryKey: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  autocompleteFn: PropTypes.func,
  optionsBuilderFn: PropTypes.func,
  optionSelectedFn: PropTypes.func,
  optionLabelFn: PropTypes.func,
  renderOptionFn: PropTypes.func,
  onChangeFn: PropTypes.func
};

export default AutocompleteField;
