export default (state, action) => {
  switch (action.type) {
    case 'SET_DATA':
      // eslint-disable-next-line no-case-declarations
      const { data, totalResults } = action.payload;
      return { ...state, data, totalResults: totalResults || state.totalResults };
    case 'CHANGE_PAGE':
      return { ...state, page: action.payload.page };
    case 'CHANGE_PAGE_SIZE':
      return { ...state, perPage: action.payload.perPage };
    case 'CHANGE_ORDER':
      // eslint-disable-next-line no-case-declarations
      const { sortBy, orderColumnId, orderDirection } = action.payload;
      return { ...state, sortBy, orderColumnId, orderDirection };
    case 'FILTER_DATA':
      // eslint-disable-next-line no-case-declarations
      const { filterText, filter } = action.payload;
      return { ...state, filter, filterText };
    case 'SHOW_MODAL':
      return { ...state, ...action.payload, openModal: true };
    case 'HIDE_MODAL':
      return { ...state, ...action.payload, openModal: false };
    default:
      return state;
  }
};
