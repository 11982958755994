import { colors, withStyles } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import React from 'react';

const styles = memoize((color) => {
  return {
    root: {
      backgroundColor: colors[color]['700']
    },
    clickable: {
      backgroundColor: colors[color]['700'],
      '&:hover, $deletable&:hover': {
        backgroundColor: colors[color]['800']
      },
      '&:focus, $deletable&:focus': {
        backgroundColor: colors[color]['900']
      }
    },
    avatar: {
      color: 'inherit',
      backgroundColor: 'rgba(255,255,255,0)'
    },
    deletable: {
      backgroundColor: colors[color]['700'],
      '&:hover': { backgroundColor: colors[color]['800'] },
      '&:focus': { backgroundColor: colors[color]['900'] },
      '&:active': { backgroundColor: colors[color]['900'] }
    },

    outlined: {
      color: colors[color]['700'],
      backgroundColor: colors.common.white,
      border: '1px solid ' + colors[color]['700'],
      '$clickable&:hover': {
        backgroundColor: colors[color]['700'],
        color: colors.common.white
      },
      '$deletable&:hover': {
        backgroundColor: colors[color]['700'],
        color: colors.common.white
      },
      '$clickable&:focus, $deletable&:focus': {
        backgroundColor: colors[color]['800'],
        color: colors.common.white
      }
    }
  };
});

const ColoredChip = ({ color, ...rest }) => {
  const Component = withStyles(styles(color))(Chip);
  return <Component {...rest} />;
};

ColoredChip.propTypes = {
  color: PropTypes.string
}

export default ColoredChip;
