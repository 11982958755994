/* eslint-disable no-use-before-define */
import Logo from '@app/components/Logo';
import { ROLES } from '@app/constants';
import useAuth from '@app/hooks/useAuth';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  makeStyles,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import {
  BarChart as BarChartIcon,
  // Bell as BellIcon,
  // BookOpen as BookOpenIcon,
  Briefcase as BriefcaseIcon,
  Calendar as CalendarIcon,
  // Command as CommandIcon,
  // Folder as FolderIcon,
  Pocket as PocketIcon,
  Target as TargetIcon,
  Trello as TrelloIcon
  // Users as UsersIcon
} from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { matchPath, useLocation } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

import NavItem from './NavItem';

const sections = [
  {
    subheader: 'Reports',
    items: [
      {
        title: 'Dashboard',
        icon: BarChartIcon,
        href: '/reports/dashboard'
      }
    ]
  },
  {
    subheader: 'Application',
    roles: Object.values(ROLES),
    items: [
      {
        title: 'Conferences',
        icon: TrelloIcon,
        href: '/conferences'
      },
      {
        title: 'Word Finder',
        icon: TargetIcon,
        href: '/word-finder'
      },
      /*{
        title: 'Agenda',
        href: '/agenda',
        icon: BookOpenIcon,
      },*/
    ]
  },
  {
    subheader: 'Management',
    roles: [ROLES.ADMIN],
    items: [
      {
        title: 'Events',
        href: '/events',
        icon: CalendarIcon
      },
      /*{
        title: 'Notifications',
        href: '/notifications',
        icon: BellIcon
      },*/
      {
        title: 'Schedules',
        icon: BriefcaseIcon,
        href: '/schedules'
      },
      {
        title: 'Users',
        href: '/users',
        icon: PocketIcon
      }
    ]
  }
];

function renderNavItems({
                          items,
                          pathname,
                          depth = 0
                        }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
                             acc,
                             pathname,
                             item,
                             depth
                           }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  // NOTE: Keep  in case we display information regarding user on the navbar
  const { user } = useAuth();

  useEffect( () => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height='100%'
      display='flex'
      flexDirection='column'
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display='flex'
            justifyContent='center'
          >
            <RouterLink to='/'>
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          {sections
            .filter(section => !section.roles || section.roles.includes(user.role))
            .map((section) => (
              <List
                key={section.subheader}
                subheader={(
                  <ListSubheader
                    disableGutters
                    disableSticky
                  >
                    {section.subheader}
                  </ListSubheader>
                )}
              >
                {renderNavItems({
                  items: section.items,
                  pathname: location.pathname
                })}
              </List>
            ))}
        </Box>
        <Divider />
        <Box p={2}>
          <Box
            p={2}
            borderRadius='borderRadius'
            bgcolor='background.dark'
          >
            <Typography
              variant='h6'
              color='textPrimary'
            >
              Need Help?
            </Typography>
            <Link
              variant='subtitle1'
              color='secondary'
              component={RouterLink}
              to='/docs'
            >
              Check our docs
            </Link>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor='left'
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant='temporary'
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor='left'
          classes={{ paper: classes.desktopDrawer }}
          open
          variant='persistent'
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
