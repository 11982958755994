import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField
} from '@material-ui/core';
import {
  Clear as ClearIcon,
  Search as SearchIcon
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';


const useStyles = makeStyles((theme) => ({
  root: {},
  queryField: {
    minWidth: 150,
    paddingLeft: theme.spacing(2)
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  }
}));


const FilterField = ({
                       filterText,
                       placeholderText,
                       handleQueryChangeFn
                     }) => {
  const classes = useStyles();
  const [value, setValue] = useState(filterText);
  return (
    <Box
      p={2}
      minHeight={45}
      display='flex'
      alignItems='center'
    >
      <Box flexGrow={1} />
      <TextField
        className={classes.queryField}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                disabled={!value.trim().length}
                onClick={() => handleQueryChangeFn(value)}
                aria-label='Search'
              >
                <SearchIcon
                  fontSize='small'
                  aria-label='search'
                />
              </IconButton>
              <Divider className={classes.divider} orientation='vertical' />
              <IconButton
                disabled={!value.trim().length}
                onClick={() => handleQueryChangeFn('')}
                aria-label='Clear search'
              >
                <ClearIcon
                  fontSize='small'
                  aria-label='clear'
                />
              </IconButton>
            </InputAdornment>

          ),
          inputProps: {
            'aria-label': placeholderText
          }
        }}
        onChange={(e) => setValue(e.target.value)}
        placeholder={placeholderText}
        value={value}
        variant='standard'
      />
    </Box>
  );
};

FilterField.defaultProps = {
  filterText: '',
  placeholderText: 'Filter items'
};

FilterField.propTypes = {
  filterText: PropTypes.string,
  placeholderText: PropTypes.string,
  handleQueryChangeFn: PropTypes.func.isRequired
};

export default FilterField;
