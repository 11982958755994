import logoSvg from '@app/assets/svg/logo.svg';
import React from 'react';

const Logo = (props) => {
  return (
    <img
      alt='Logo'
      src={logoSvg}
      {...props}
    />
  );
};

export default Logo;
