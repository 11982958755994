import { environment } from '@app/environments/environment';
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import { history } from './history';
import localStorage from './localStorage';

const axiosInstance = axios.create({
  baseURL: environment.apiUrl,
});

axiosInstance.interceptors.request.use((config) => {
  config.params && delete config.params.queryKey; // remove
  return config;
});

const refreshAuthLogic = async (failedRequest) => {
  let tokens = localStorage.get('accessTokens');
  if (tokens) {
    try {
      localStorage.rm('accessTokens');
      const tokenRefreshResponse = await axiosInstance.post(
        '/auth/refresh-tokens',
        {
          refreshToken: tokens.refresh.token
        },
        {
          skipAuthRefresh: true
        }
      );
      tokens = tokenRefreshResponse.data;
      localStorage.set('accessTokens', tokens);
      failedRequest.response.config.headers.Authorization = `Bearer ${tokens.access.token}`;
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${tokens.access.token}`;
    } catch (err) {
      if (err.response.status === 401) {
        history.push('/');
      }
    }
  }
  return Promise.resolve();
};

// Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic);

export default axiosInstance;
