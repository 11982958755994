import { AuthGuard, GuestGuard, LoadingScreen } from '@app/components';
import DashboardLayout from '@app/layouts/DashboardLayout';
import MainLayout from '@app/layouts/MainLayout';
import React, {
  Fragment,
  lazy,
  Suspense
} from 'react';
import {
  Redirect,
  Route,
  Switch
} from 'react-router-dom';

export const renderRoutes = (routes = []) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes
          .map((route, i) => {
            const Guard = route.guard || Fragment;
            const Layout = route.layout || Fragment;
            const Component = route.component;

            return (
              <Route
                key={i}
                path={route.path}
                exact={route.exact}
                render={(props) => (
                  <Guard>
                    <Layout>
                      {route.routes
                        ? renderRoutes(route.routes)
                        : <Component {...props} />}
                    </Layout>
                  </Guard>
                )}
              />
            );
          })}
      </Switch>
    </Suspense>
  );
};

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('@app/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('@app/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/reset-password',
    component: lazy(() => import('@app/views/auth/ResetPasswordView'))
  },
  {
    exact: true,
    path: '/forgot-password',
    component: lazy(() => import('@app/views/auth/ForgotPasswordView'))
  },
  {
    path: '/',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/profile',
        component: lazy(() => import('@app/views/user/ProfileView'))
      },
      {
        exact: true,
        path: '/conferences/:id',
        component: lazy(() => import('@app/views/placeholder/PanelView'))
      },
      {
        exact: true,
        path: '/conferences',
        component: lazy(() => import('@app/views/conference/ConferenceListView'))
      },
      {
        exact: true,
        path: '/word-finder',
        component: lazy(() => import('@app/views/keyword/KeywordListView'))
      },
      {
        exact: true,
        path: '/word-finder/keywords/:keywordId',
        component: lazy(() => import('@app/views/keyword/KeywordDetailsView'))
      },
      {
        exact: true,
        path: '/events',
        component: lazy(() => import('@app/views/event/EventListView'))
      },
      {
        exact: true,
        path: '/conferences/:conferenceId/events',
        component: lazy(() => import('@app/views/event/EventListView'))
      },
      {
        exact: true,
        path: '/conferences/:conferenceId/events/:eventId/sessions',
        component: lazy(() => import('@app/views/session/SessionListView'))
      },
      {
        exact: true,
        path: '/conferences/:conferenceId/events/:eventId/abstracts',
        component: lazy(() => import('@app/views/abstract/AbstractListView'))
      },
      {
        exact: true,
        path: '/conferences/:conferenceId/events/:eventId/abstracts/create',
        component: lazy(() => import('@app/views/abstract/AbstractEditView'))
      },
      {
        exact: true,
        path: '/conferences/:conferenceId/events/:eventId/abstracts/edit/:id',
        component: lazy(() => import('@app/views/abstract/AbstractEditView'))
      },
      {
        exact: true,
        path: '/conferences/:conferenceId/events/:eventId/sessions/:id',
        component: lazy(() => import('@app/views/placeholder/PanelView'))
      },
      {
        exact: true,
        path: '/conferences/:conferenceId/events/:eventId/sessions/:sessionId/session-items',
        component: lazy(() => import('@app/views/sessionItem/SessionItemListView'))
      },
      {
        exact: true,
        path: '/schedules',
        component: lazy(() => import('@app/views/schedule/ScheduleListView'))
      },
      {
        exact: true,
        path: '/notifications',
        component: lazy(() => import('@app/views/placeholder/PanelView'))
      },
      {
        exact: true,
        path: '/users',
        component: lazy(() => import('@app/views/user/UserListView'))
      },
      {
        exact: true,
        path: '/reports/dashboard',
        component: lazy(() => import('@app/views/reports/DashboardView'))
      },
      {
        exact: true,
        path: '/reports',
        // eslint-disable-next-line react/display-name
        component: () => <Redirect to='/reports/dashboard' />
      },
      {
        exact: true,
        path: '/',
        // eslint-disable-next-line react/display-name
        component: () => <Redirect to='/reports/dashboard' />
      },
      {
        // eslint-disable-next-line react/display-name
        component: () => <Redirect to='/404' />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        // eslint-disable-next-line react/display-name
        component: () => <Redirect to='/404' />
      }
    ]
  }
];

export default routes;
