import {
  CookiesNotification,
  GlobalStyles,
  ScrollReset,
  SettingsNotification
} from '@app/components';
import { AuthProvider } from '@app/contexts/AuthContext';
import { DialogProvider } from '@app/contexts/DialogContext';
import useSettings from '@app/hooks/useSettings';
import routes, { renderRoutes } from '@app/routes';
import { createTheme } from '@app/theme';
import MomentUtils from '@date-io/moment';
import {
  jssPreset,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Router } from 'react-router-dom';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false
    }
  }
});


const App = () => {
  const { settings } = useSettings();
  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <QueryClientProvider client={queryClient}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DialogProvider>
              <SnackbarProvider
                dense
                maxSnack={3}
              >
                <Router history={history}>
                  <AuthProvider>
                    <GlobalStyles />
                    <ScrollReset />
                    <CookiesNotification />
                    <SettingsNotification />
                    {renderRoutes(routes)}
                  </AuthProvider>
                </Router>
              </SnackbarProvider>
            </DialogProvider>
          </MuiPickersUtilsProvider>
        </QueryClientProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
