import SplashScreen from '@app/components/SplashScreen';
import { axios } from '@app/utils';
import localStorage from '@app/utils/localStorage';
import jwtDecode from 'jwt-decode';
import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessTokens) => {
  if (accessTokens) {
    localStorage.set('accessTokens', accessTokens);
    axios.defaults.headers.common.Authorization = `Bearer ${accessTokens.access.token}`;
  } else {
    localStorage.rm('accessTokens');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    case 'UPDATE_USER': {
      const { user } = action.payload;
      return { ...state, user };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  logout: () => {
  }
});

// eslint-disable-next-line react/prop-types
export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const login = async (email, password) => {
    const response = await axios.post(
      '/auth/login',
      { email, password },
      {
        skipAuthRefresh: true
      }
    );

    const { tokens, user } = response.data;

    setSession(tokens);
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const updateAuthUser = (user) => {
    dispatch({ type: 'UPDATE_USER', payload: { user } });
  };

  useEffect(async () => {
    const initialize = async () => {
      try {
        const tokens = localStorage.get('accessTokens');

        if (tokens && isValidToken(tokens.access.token)) {
          setSession(tokens);

          const response = await axios.get('/users/me');
          const user = response.data;

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    await initialize();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        updateAuthUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
