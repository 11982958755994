export default {
  set(key, value) {
    localStorage.setItem(key, JSON.stringify({ data: value }));
  },
  get(key, defaultValue) {
    let value = null;
    try {
      value = JSON.parse(localStorage.getItem(key));
    } catch (e) {
      if (localStorage[key]) {
        value = { data: localStorage.getItem(key) };
      } else {
        value = null;
      }
    }
    if (!value) {
      return defaultValue;
    }

    if (typeof value === 'object' && typeof value.data !== 'undefined') {
      return value.data;
    }
  },
  rm(key) {
    localStorage.removeItem(key);
  },
  flush() {
    localStorage.clear();
  }
};
