import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'prismjs/prism';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-jsx';
import 'nprogress/nprogress.css';
import '@app/assets/css/prism.css';
import '@app/mixins/chartjs';

import { enableES5 } from 'immer';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './app/App';
import { SettingsProvider } from './contexts/SettingsContext';
import * as serviceWorker from './serviceWorker';

enableES5();

ReactDOM.render(
  <React.StrictMode>
    <SettingsProvider>
      <App />
    </SettingsProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.register();
