import {
  Breadcrumbs,
  Grid,
  Link,
  Typography
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const BreadCrumbs = ({ title, items }) => {
  const renderItems = (items) => {
    return items.map((item, idx) => {
      if (item.url) {
        return (
          <Link key={idx} variant='body1' color='inherit' to={item.url} component={RouterLink}>
            {item.text}
          </Link>
        );
      } else {
        return <Typography key={idx} variant='body1' color='textPrimary'>{item.text}</Typography>;
      }
    });
  };
  return (

    <Grid item>
      <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />}
                   arial-label='breadcrumb'
      >
        {renderItems(items)}
      </Breadcrumbs>
      <Typography
        variant='h3'
        color='textPrimary'
      >
        {title}
      </Typography>
    </Grid>
  );
};

BreadCrumbs.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string,
    text: PropTypes.string.isRequired
  })).isRequired
};

export default BreadCrumbs;
